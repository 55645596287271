import * as React from "react";
import { Component } from 'react';
import Layout from '../../components/global/layout.js'
import Content from '../../components/history-of-hiv/content.js'
import '@fortawesome/fontawesome-free/css/all.css'; 
import '../../sass/app.scss';

class HistoryOfHIV extends Component {
  render() {
    return (
      <>
        <Layout section="hiv-landscape" page="history">
          <Content section="hiv-landscape" page="history" />
        </Layout>
      </>
    )
  }
}

export default HistoryOfHIV;