import * as React from "react";
import { Component } from "react";
import ScientificAdvacementDesktop from "../../images/scientific-advancement.png";
import ScientificAdvacementMobile from "../../images/scientific-advancement-mobile.png";
import DisparitiesDesktop from "../../images/disparities-discrimination.png";
import DisparitiesMobile from "../../images/disparities-discrimination-mobile.png";
import AdvocacyDesktop from "../../images/advocacy-activism.png";
import AdvocacyMobile from "../../images/advocacy-activism-mobile.png";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import BackToTop from "../global/BackToTop.js";
import HTML5Video from "../global/video.js";
import References from "../global/references.js";

let VideoSrc =
  "https://player.vimeo.com/progressive_redirect/playback/917245533/rendition/1080p/file.mp4?loc=external&signature=4bc9bafe13682fc8b8c264b6e4adfd4bf785af82327f8c9a8f616d8fc2ad5437";

  /*
  if(typeof window !== 'undefined'){
    window.addEventListener('message', function(event) {
      //if (event.origin !== "http://*.gileadhiv.com/") return;
      console.log(event);
      if (event.data === 'timelineStart') {
          dataLayer.push({
              'event': 'timelineEvent',
              'clickData': event.data
          });
      } else if (event.data.targetClass === "decade-label") {
        console.log(event.data);
        dataLayer.push({
          'event': 'decadeEvent',
          'decade': event.data.decade
        });
      }
    }, false);
  }
  */

class Content extends Component {
  constructor() {
    super();
    this.state = {
      show: false,
    };
  }
  handleClose = (show) => {
    this.setState({ show: false });
  };
  handleShow = (show) => {
    this.setState({ show: true });
  };
  render() {
    return (
      <div className={`${this.props.show ? "d-block" : "d-none"}`}>
        <Container fluid className="hero history-of-hiv" />
        <Container>
          <HTML5Video
            handleClose={this.handleClose}
            handleShow={this.handleShow}
            show={this.state.show}
            thumbnail={"/history-of-hiv-thumbnail.png"}
            url={VideoSrc}
            videoTrackLabel="History of HIV Video"
          />
          <Row>
            <Col xs={10} className="m-auto px-md-0">
              <div className="hero-callout">
                <h1 className="title">
                  <span className="sub-title d-block">History of the HIV Epidemic</span>
                  Progress Through Innovation and Activism
                </h1>
              </div>
            </Col>
          </Row>
        </Container>
        <Container>
          <Row>
            <Col xs={9} lg={10} className="m-auto px-0">
              <Container>
                <Row>
                  <Col xs={12} className="p-0">
                    <p className="pt-2">
                      The first published report of what would ultimately become known as HIV and AIDS appeared in the Centers for Disease
                      Control and Prevention (CDC) <em>Morbidity and Mortality Weekly Report</em> in June 1981.<sup>1</sup> When these initial cases emerged,
                      very little was known about the disease; it did not have an agreed-upon name, researchers had not yet determined
                      what caused it, there were no tests or recognized treatments, and by the time most patients presented with symptoms,
                      they had only months to live.<sup>2</sup>
                    </p>
                    <p>
                      In the United States, where the disease was first seen in already marginalized communities—including men who have sex with men (MSM),
                      people who inject drugs, and people who exchange sex for money—the public and policymakers
                      were slow to respond to this new health threat.<sup>3-5</sup>
                    </p>
                    <p>
                      The history of the HIV epidemic highlights amazing scientific discovery and fierce advocacy in the face of adversity,
                      but it also shows stigma, discrimination, and disparities based on race, sexual orientation, and socioeconomic status. Though there is neither a cure nor a vaccine for HIV, scientific advancements have turned it from a death sentence into a manageable chronic condition.<sup>2</sup>
                    </p>
                    <h2 className="title">
                      Scientific Advancement
                    </h2>
                    <img className="d-none d-md-block w-85 mx-auto" src={ScientificAdvacementDesktop} alt="3D model of HIV cell" />
                    <img className="d-block d-md-none w-85 mx-auto" src={ScientificAdvacementMobile} alt="3D model of HIV cell" />
                    <p>
                      In September 1982, the CDC labeled the condition acquired immunodeficiency syndrome (AIDS), and by 1984,
                      researchers had identified the cause as a virus they named human immunodeficiency virus (HIV). Additional advances
                      in knowledge included identifying how it spread and what it did to the immune system. By 1985, there was a test for
                      HIV that could determine those who had the virus before they had symptoms.<sup>2,6</sup>
                    </p>
                    <p >
                      Treatment advances came next. In 1987, the antiretroviral drug azidothymidine (AZT), now called zidovudine, offered
                      a glimmer of hope. It was meant to prolong the lives of those living with HIV
                      and was considered moderately effective. Importantly, however, it was also found to prevent mother-to-child transmission if taken
                      during pregnancy. New combination therapies were introduced in the early-to-mid 1990s that included two antiretroviral drugs.
                      These were more effective at limiting the amount of virus in the body but often involved people taking more than 20
                      pills a day and dealing with numerous side effects.<sup>2,7,8</sup>
                    </p>
                    <p>
                      A major breakthrough came in 1996 with the introduction of highly active antiretroviral therapy (HAART),
                      a combination of multiple drugs, including a newly developed class of antiretrovirals called protease
                      inhibitors.<sup>2</sup> HAART quickly became the standard of care in the US, and in the following year,
                      AIDS-related deaths declined by 47%.
                      Over the next decade, improvements in these therapies, including single pills that combined multiple medications, meant that people
                      could take fewer pills. Testing also continued to improve over the years, with
                      the first oral test approved in 1994, the first at-home testing kit approved in 1996, and the first rapid test approved in 2002.<sup>6</sup>
                    </p>
                    <p>
                      Additional medical advancements, most notably the advent of pre-exposure prophylaxis (PrEP) medicine in 2012, have
                      helped us get closer to achieving this goal of ending HIV transmission. PrEP is a prevention strategy
                      for people who are at risk of HIV. It may involve methods such as a prescription medicine and safer sex practices to reduce that
                      risk.<sup>2</sup> Research shows that PrEP medicine is highly effective if taken correctly. The CDC recommends all sexually active adults and adolescents be informed about PrEP for the prevention of HIV acquisition. While PrEP use has increased substantially over the past decade, hundreds of thousands of people could still benefit from PrEP.<sup>2,9-11</sup>
                    </p>
                    <p>
                      While there is no cure for HIV, scientific advancements have helped make significant progress toward helping end the epidemic.<sup>2</sup>
                    </p>

                    <h2 className="title">
                      Disparities and Discrimination
                    </h2>
                    <img className="d-none d-md-block w-85 mx-auto mb-4" src={DisparitiesDesktop} alt="Photo of a diverse group of people" />
                    <img className="d-block d-md-none w-85 mx-auto mb-3" src={DisparitiesMobile} alt="Photo of a diverse group of people" />
                    <p>
                      HIV was first identified in MSM, which served to increase the discrimination this community was already facing.
                      It quickly became clear, however, that the emerging HIV epidemic was impacting other communities as well. The first
                      cases of HIV in women were identified in 1983. And, in 1986, the CDC reported that AIDS was disproportionately impacting
                      Blacks/African Americans and Hispanic/Latino Americans.<sup>6,12</sup>
                    </p>
                    <p>
                      Early in the epidemic, misinformation was abundant. The public worried that they could get infected through casual
                      contact like shaking hands, sharing a drink, or using public restrooms.<sup>4,13</sup> In 1985, a young
                      man named Ryan White became the face of AIDS discrimination when his school refused to let him in the building. White had
                      hemophilia and contracted HIV from a blood transfusion, and the school feared that he would infect classmates. His court
                      battle against the district made national news and helped educate the public about how HIV was and wasn’t spread, and
                      it raised awareness of the discrimination people living with HIV faced every day.<sup>6,14,15</sup>
                    </p>
                    <p>
                      Still, public health messages throughout the 1990s and early 2000s focused on personal behavior—whether it was
                      “promiscuous” sex or sharing needles for drug use.<sup>3</sup> This fueled blame and further marginalized the communities most
                      impacted by HIV and meant that people living with HIV and those perceived to be at higher risk faced increased discrimination in areas
                      like medical care, housing, and employment.<sup>16,17</sup>
                    </p>
                    <h2 className="title">Advocacy and Activism</h2>
                    <img className="d-none d-md-block w-85 mx-auto mb-4" src={AdvocacyDesktop} alt="A group of protestors raising their arms" />
                    <img className="d-block d-md-none w-85 mx-auto mb-3" src={AdvocacyMobile} alt="A group of protestors raising their arms" />
                    <p>
                      In the face of this kind of stigma, many of the communities most impacted by HIV became fierce advocates for their needs.
                      Founded out of the LGBTQ+ movement, the HIV community has a history of activism that
                      has shaped the course of the epidemic.
                    </p>
                    <p>
                      In 1983, a group of activists met at a gay and lesbian health conference and discussed the importance of self-determination for
                      those most impacted by HIV. The document they created became known as <i>The Denver Principles</i>.
                      It rejected terms like “AIDS victims” and “AIDS patients” that implied defeat or helplessness and introduced
                      the phrase “people with AIDS” to the lexicon. And, it enumerated ways that people could
                      support those most impacted by the epidemic.<sup>6,18</sup>
                    </p>
                    <p>
                      Throughout the 1980s and 1990s, groups like GMHC (formerly the Gay Men’s Health Crisis), ACT UP (the AIDS
                      Coalition to Unleash Power), NMAC (formerly National Minority AIDS Council), the Latino Commission on
                      AIDS, and the Black AIDS Institute (BAI) formed to offer direct services, provide education, amplify the voices
                      of marginalized communities, and advocate for laws and policies that could help those suffering most. They fought
                      for increased investment in research, care, prevention, and other services that people living
                      with HIV needed, like housing and employment programs.<sup>19-24</sup>
                    </p>
                    <p>
                      These groups and others also pushed hard for a coordinated government strategy to address HIV in this country. In 1995, President Bill
                      Clinton established the Presidential Advisory Council on HIV/AIDS (PACHA), which included AIDS activists
                      and leaders of advocacy groups.<sup>6,25,26</sup> Still, it wasn’t until 2010 that the federal government, under the Obama
                      administration, released the first comprehensive National HIV/AIDS Strategy. Members of those communities most impacted by
                      the epidemic were involved in crafting this plan.<sup>6,27</sup>
                    </p>
                    <p>
                      AIDS activists have now spent decades moving the government, industries, and individuals forward, and their contribution to the progress we have
                      made cannot be overstated. Moreover, AIDS service organizations across the country continue to play an
                      important role in making sure people have access to the prevention, treatment, and care they need.
                    </p>
                    <h2 className="title">Looking Back and Moving Forward</h2>
                    <p>
                      In 2021, AIDS turned 40 while the world was immersed in another pandemic. It helped shed light on
                      the tremendous scientific progress we made in understanding, treating, and preventing HIV.
                      A virus that was once a death sentence is now a chronic manageable condition. While there is no cure,
                      with care and treatment as directed by a healthcare provider, people living with HIV can
                      live longer, healthier lives.<sup>28,29</sup>
                    </p>
                    <p>
                      Now we must all focus our attention on reducing HIV discrimination and disparities and helping break the barriers to equitable
                      care so that those most in need have access to these advancements in prevention and care.
                    </p>
                  </Col>
                </Row>
              </Container>
            </Col>
          </Row>
        </Container>
        <References page={this.props.page} />
      </div>
    )
  }
}

export default Content;